var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wscn-http404-container" }, [
    _c("div", { staticClass: "wscn-http404" }, [
      _c("div", { staticClass: "bullshit" }, [
        _c("div", { staticClass: "bullshit__oops" }, [_vm._v("Session 만료")]),
        _c("div", { staticClass: "bullshit__headline" }, [
          _vm._v(_vm._s(_vm.message)),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }